import React from 'react';
import { Blank } from 'grommet-icons';

const IconFandeck = (props) => (
  <Blank
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 55 55"
    {...props}
    style={{ strokeWidth: 0 }}
  >
    <polygon points="3.59 30.29 3.59 30.3 3.6 30.29 3.59 30.29" />
    <path d="M53.31,28.12a2.42,2.42,0,0,0,0-1.83l-2.57-6.06.6-.41h0a2.37,2.37,0,0,0,.64-3.3L43.9,4.57a2.4,2.4,0,0,0-3.31-.64L3.39,29.08a2.3,2.3,0,0,0-.75.42,3.22,3.22,0,0,0-.41.32,2.38,2.38,0,0,0-.7,1.69l0,14.41a2.39,2.39,0,0,0,2.38,2.39l44.53,0h0A2.37,2.37,0,0,0,50.8,46V39.4l.44-.09a2.32,2.32,0,0,0,1.52-1,2.36,2.36,0,0,0,.37-1.79l-1.36-7,.25-.11A2.32,2.32,0,0,0,53.31,28.12ZM41.21,4.85a1.24,1.24,0,0,1,.71-.22A1.28,1.28,0,0,1,43,5.19l8.07,11.94a1.28,1.28,0,0,1-.35,1.78l-.41.28L47.69,13a2.4,2.4,0,0,0-3.13-1.27L8.34,27.06Zm9,17a2.34,2.34,0,0,0-.64-.82,2.41,2.41,0,0,0-.5-.33,2.23,2.23,0,0,0-.68-.2,2.33,2.33,0,0,0-.79,0L9.08,27.94,45,12.76a1.29,1.29,0,0,1,1.68.68l2.7,6.38.22.52.21.51Zm-.51,16.66V46a1.24,1.24,0,0,1-.38.91,1.26,1.26,0,0,1-.9.37h0l-38,0H3.89a1.3,1.3,0,0,1-1.28-1.29l0-10v-4.4A1.3,1.3,0,0,1,3,30.6a1.38,1.38,0,0,1,.28-.19,1.88,1.88,0,0,1,.3-.12h0a1.07,1.07,0,0,1,.31-.06h0l30,0H48.44l.08,0a1.29,1.29,0,0,1,.72.29,1.26,1.26,0,0,1,.48,1Zm2.35-1.76a1.3,1.3,0,0,1-.2,1,1.22,1.22,0,0,1-.82.54l-.23.05V31.57a2.4,2.4,0,0,0-.49-1.44,2.78,2.78,0,0,0-.42-.42,2.42,2.42,0,0,0-.58-.35,2.66,2.66,0,0,0-.89-.18H35.54l-26.84,0,37.68-7.29,1.37-.26.07,0,.24,0a1.33,1.33,0,0,1,.53.13,1.28,1.28,0,0,1,.73.91l1.21,6.27.11.56.11.55ZM51.6,28.4l0,0L50.4,22.37a2.91,2.91,0,0,0-.12-.39l2,4.74a1.28,1.28,0,0,1-.68,1.68Z" />
    <path d="M6.19,31.67a2.23,2.23,0,0,0-2.1,1.48,2.14,2.14,0,0,0-.14.76v0a1,1,0,0,0,0,.17,2.17,2.17,0,0,0,.53,1.26,2.2,2.2,0,0,0,1.61.78h.08a2.24,2.24,0,0,0,0-4.47Zm0,3.37a1.14,1.14,0,1,1,1.13-1.13A1.14,1.14,0,0,1,6.19,35Z" />
  </Blank>
);

export default IconFandeck;
